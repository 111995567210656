.game-card {
    &__card-info {
        display: flex;
        flex-direction: column;
    }

    &__price-with-heart {
        display: flex;
        align-items: flex-end;
    }
    &__price-wrapper {
        display: flex;
        flex-direction: column;
        color: var(--color-chateau);
        font: var(--font-p3-regular-comp);
        gap: 8px;
    }
}