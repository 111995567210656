.filter-settings-mobile {
    display: flex;
    flex-direction: column;
    color: var(--color-alabaster);
    height: 100%;

    &__search-box {
        padding-top: 40px;
    }
    &__sort-order {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        color: var(--color-chateau);
        font: var(--font-p3-regular-comp);
    }
    &__genre-select {
        padding-top: 20px;
    }
    &__tags-select {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        margin: 20px 0;
    }
    &__others-select {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }
    &__platforms {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
    }
}
.filter-settings-mobile__title {
    font: var(--font-h2-wide);
    width: 70%;
    font-weight: 700;
}
.filter-settings-mobile__range-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
}
.filter-settings-mobile__buttons {
    padding-bottom: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;

    @media (min-width: 360px) {
        padding-bottom: 0;
        grid-column-gap: 16px;
    }

    @media (max-width: 678px) {
        button {
            min-width: auto;
        }
    }
}
.filter-settings-mobile__blank-space {
    display: flex;
    min-height: 50px;
}
.filter-settings-mobile__available-input {
    margin: 0 0 20px;
}
