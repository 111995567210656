@import 'src/styles';

.empty-search {
    text-align: center;
    margin: 110px auto;
}

@include mq-phone-only {
    .empty-search {
        margin: 36px auto;
        max-width: 225px;
    }
}
