@use "sass-rem" as rem;
@import 'src/styles';

.game-card {
    cursor: pointer;
    display: inline-block;
    position: relative;

    --platforms-margin: 16px 0 0 16px;

    a {
        text-decoration: none;
    }
}

.game-card__card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.game-card--mobile {
    display: flex;
    flex-direction: column;

    .game-card__card-wrapper {
        display: grid;
        grid-template-columns: 42% 1fr;
        gap: 20px;
    }

    .game-card__image {
        width: 100%;
        aspect-ratio: 136 / 80;
        border-radius: 8px;
    }

    .game-card__game-title {
        margin: 12px 0 0 0;
    }
}

.game-card--slider {
    padding-bottom: 120px;
}

.game-card--slider .game-card__card-wrapper {
    position: relative;
}

.game-card--slider .game-card__info {
    position: absolute;
    bottom: 0;
}

.game-card--verticalBig {
    width: var(--width-vertical-big);
    --title-margin: #{rem.convert(24px 0 16px 0)};
}

.game-card--verticalBig .game-card__image {
    height: var(--height-vertical-big);
}

.game-card--horizontBig {
    width: var(--width-horizontal-big);
    --title-margin: #{rem.convert(24px 0 16px 0)};
}

.game-card--horizontBig .game-card__image {
    height: var(--height-horizontal-big);
}

.game-card--horizontSmall {
    width: var(--width-horizontal-small);
    --title-margin: #{rem.convert(24px 0 16px 0)};

    .game-card__game-title {
        display: none;
    }

    .game-card__info {
        position: absolute;
    }
}

.game-card--horizontSmall .game-card__image {
    height: var(--height-horizontal-small);
}

// MODE --HEADER
// стили для карточек игр в поиске хедера
.game-card--header.game-card--horizontSmall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    transition: background 0.4s ease;

    &:hover {
        background: rgba(29, 32, 35, 0.6);
    }

    .game-card__card-wrapper {
        display: grid;
        gap: 20px;
        grid-template-columns: 138px 1fr;
    }

    .game-card__image {
        width: 100%;
        aspect-ratio: 136 / 80;
        border-radius: 8px;
    }

    &.game-card--horizontSmall .game-card__image {
        height: 80px;
        border-radius: 12px;
    }

    .game-card__game-title.game-card__game-title--header {
        margin-top: 0;
        margin-bottom: 12px;
    }

    .game-card__info {
        position: relative;
    }
}

.game-card--scalable-all.game-card--horizontBig .game-card__image {
    aspect-ratio: 280 / 162;
    height: auto;
}

.game-card--scalable-all.game-card--horizontBig {
    width: 100%;
}

.game-card__game-title.game-card__game-title--header {
    display: none;
}

.game-card__additional-info {
    font: var(--font-p3-medium-comp);
    color: var(--color-white);
}

.game-card__offer-promo-remains {
    max-width: max-content;
}

.game-card__offer-promo-remains--day {
    background: var(--color-discount-back);
    color: var(--color-white);
    padding: 2px 8px;
    border-radius: 16px;
}

.game-card__price-container {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.game-card__image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
}

.game-card__image-wrapper--mobile {
    border-radius: 8px;
}

.game-card__favorites-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(29, 32, 35, 0.9);
    border-top-left-radius: 30px;

    z-index: 11; // Must be greater than slider arrow background

    width: 48px;
    height: 48px;

    & > div {
        padding: 8px 5px 8px 11px;
    }
}

@include mq-phone-only {
    .game-card--scalable.game-card--verticalBig .game-card__image {
        aspect-ratio: 280 / 162;
        height: auto;
    }
    .game-card--scalable.game-card--verticalBig {
        width: 100%;
    }

    .game-card--header.game-card--horizontSmall {
        & .game-card__game-title.game-card__game-title--mobile {
            display: block;
        }
    }
}

@include mq-tablet-768-up {
    .game-card--header.game-card--horizontSmall {
        .game-card__card-wrapper {
            grid-template-columns: 212px 1fr;
        }

        &.game-card--horizontSmall .game-card__image {
            height: 117px;
        }
    }

    .game-card__game-title.game-card__game-title--header {
        display: block;
    }

    .game-card__game-title {
        font: var(--font-p2-medium-comp);
    }
}

@include mq-desktop-1024-up {
    .game-card--header.game-card--horizontSmall {
        padding: 0 17px;

        .game-card__card-wrapper {
            padding: 8px 0;
        }
    }
}

@include mq-desktop-1440-up {
    .game-card__favorites-btn {
        width: 56px;
        height: 56px;

        & > div {
            padding: 12px 9px 12px 15px;
        }
    }

    .game-card__game-title {
        font: var(--font-h3-comp);
    }
}

@include mq-desktop-1920-up {
    .game-card {
        --platforms-margin: 20px 0 0 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
    .game-card--scalable.game-card--verticalBig:not(.game-card--slider) .game-card__image {
        aspect-ratio: 202 / 260;
        height: auto;
    }
    .game-card--scalable.game-card--verticalBig:not(.game-card--slider) {
        width: 100%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .game-card--verticalBig:not(.game-card--slider) .game-card__image {
        aspect-ratio: 202 / 260;
        height: auto;
    }
    .game-card--verticalBig:not(.game-card--slider) {
        width: 100%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .game-card--slider.game-card--horizontBig:not(.game-card--slider) .game-card__image {
        aspect-ratio: 280 / 162;
        height: auto;
    }
    .game-card--slider.game-card--horizontBig:not(.game-card--slider) {
        width: 100%;
    }
}
