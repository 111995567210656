.game-image {
    min-width: 71px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    mix-blend-mode: normal;
    cursor: inherit;
    opacity: 1;
    transition: opacity 225ms;
    position: relative;
}
.game-image__tickbox {
    position: absolute;
    background: rgba(61, 67, 74, 0.76) url('/images/icons/checked-game.svg') no-repeat center;
    width: 100%;
    height: 100%;
    background-size: 50%;
}
.game-image__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background-color: rgba(#626c77, 0.25);
    user-select: none;
}
.game-image__image--empty {
    object-fit: contain;
}
.game-image__disabled {
    position: absolute;
    background: rgba(61, 67, 74, 0.76);
    width: 100%;
    height: 100%;
    cursor: default;
}
.game-image__hover-content {
    position: absolute;
    width: 100%;
    height: 100%;

    button {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 14px;
        width: 100%;
        background: var(--color-mts-company);
        border: none;
        text-align: center;
        border-radius: 8px;
        font-size: var(--font-p2-regular-comp);
        color: var(--color-white);
        cursor: pointer;
        transition: background 0.3s ease;
        font: var(--font-p2-medium-comp);
        white-space: nowrap;
        width: 277px;

        &:hover {
            background: var(--color-mts-company-darken);
        }
    }
}
.game-image--active {
    mix-blend-mode: luminosity;
    opacity: 0.4;
}
.game-image--clickable {
    cursor: pointer;
}

@keyframes shift {
    0% {
        background-position: left;
    }

    100% {
        background-position: right;
    }
}

.game-image--small {
    border-radius: 12px;

    @media only screen and (max-width: 767px) {
        border-radius: 8px;
    }
}

.game-image--no-border-radius {
    border-radius: 0;
}
