.sort-indicator {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 4px 8px;
    border-radius: 6px;
    color: var(--color-white);
    font: var(--font-p2-medium-comp);
    cursor: pointer;
    user-select: none;
}

.sort-indicator--active {
    color: var(--color-cheteau);
}

.sort-indicator__direction-icon {
    padding-bottom: 2px;
}

.sort-indicator__direction-icon--desc {
    transform: rotateZ(180deg);
    padding-bottom: 0px;
}
