.checkbox {
    display: flex;
    align-items: center;

    label {
        margin-left: 16px;
        cursor: pointer;
        font: var(--font-h3-comp);
    }
}
