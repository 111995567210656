@import 'src/styles';

.select__wrapper {
    position: relative;
    width: 100%;
}

.select {
    color: var(--color-alabaster);
    background: rgba(#626c77, 0.25);
    border-radius: 8px;
    border: 1px solid var(--color-black-gray);
    box-sizing: border-box;
    font: var(--font-p2-regular-comp);
    z-index: 2;
    width: 100%;
    outline: none;
    position: relative;
    min-height: 44px;
    padding: 9px 40px 9px 12px;
    cursor: pointer;

    &:hover:not(.select--open) {
        border: 1px solid var(--color-white);
    }
}

.select--open {
    border: 1px solid var(--color-lightBlueberry);
}

.select__placeholder {
    font: var(--font-p2-regular-comp);
    color: var(--color-chateau) !important;

    &--filled {
        color: var(--color-alabaster) !important;
    }
}

.select__option {
    position: relative;
    font: var(--font-p2-regular-comp);
    padding: 10px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: var(--color-ebonyClay);
    z-index: 3;

    &:hover {
        background-color: rgba(98, 108, 119, 0.25);
    }

    &--clear {
        font-style: italic;
        color: var(--color-chateau);
    }

    &--selected {
        background-color: rgba(98, 108, 119, 0.25);

        &:after {
            content: ' ';
            background-image: url('/images/icons/selected.svg');
            position: absolute;
            width: 14px;
            height: 10px;
            top: 17px;
            right: 17px;
        }
    }

    .select__icon {
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        > img {
            margin: 0 auto;
            filter: invert(1);
        }
    }
}

.select__chevron {
    color: var(--color-chateau) !important;
    position: absolute;
    display: flex;
    height: 100%;
    top: 0;
    align-items: center;
    right: 12px;
}

@include mq-phone-only {
    .select:hover:not(.select--open) {
        border: 1px solid var(--color-black-gray);
    }
}
