.range-dropdown__wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.range-dropdown {
    color: var(--color-alabaster);
    background: rgba(#626c77, 0.25);
    border-radius: 8px;
    border: 1px solid var(--color-black-gray);
    box-sizing: border-box;
    font: var(--font-p2-regular-comp);
    z-index: 2;
    width: 100%;
    outline: none;
    position: relative;
    min-height: 44px;
    padding: 9px 40px 9px 12px;
    cursor: pointer;

    &:hover:not(.range-dropdown--open) {
        border: 1px solid var(--color-white);
    }
}

.range-dropdown--open {
    border: 1px solid var(--color-lightBlueberry);
}

.range-dropdown__placeholder-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--font-p2-regular-comp);
    color: var(--color-chateau) !important;

    &--filled {
        color: var(--color-alabaster) !important;
    }
}

.range-dropdown__divider {
    width: 100%;
    height: 1px;
    background-color: rgba(#7f8c99, 0.35);
    margin: 12px 0;
}

.range-dropdown__inputs {
    margin: 0 12px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 16px;

    @media (min-width: 1440px) {
        grid-template-rows: none;
        grid-row-gap: unset;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8px;
    }
}

.range-dropdown__controls {
    display: flex;
    padding: 0px 12px 10px;
    gap: 8px;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1440px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.range-dropdown__chevron {
    color: var(--color-chateau) !important;
    position: absolute;
    display: flex;
    height: 100%;
    top: 0;
    align-items: center;
    right: 12px;
}
