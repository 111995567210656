.dropdown__wrapper {
    position: relative;
    width: 100%;
}

.dropdown {
    color: var(--color-alabaster);
    background: var(--color-background-standard);
    border-radius: 12px;
    box-sizing: border-box;
    font: var(--font-p2-regular-comp);
    width: 100%;
    outline: none;
    border: none;
    position: relative;
    min-height: 44px;
    padding: 10px 45px 10px 16px;
    cursor: pointer;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    @media (min-width: 960px) {
        min-height: 52px;
        padding: 15px 45px 15px 20px;
    }

    @media (max-width: 960px) {
        min-height: 44px;
        max-height: 44px;
        padding: 10px 19px 11px;
    }

    @media (max-width: 1023px) {
        font: var(--font-p3-regular-comp);
    }

    &--open {
        background-color: var(--color-ebonyClay);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: none;
    }

    &:focus {
        background-color: var(--color-ebonyClay);
    }
}

.dropdown__option {
    position: relative;
    font: var(--font-p2-regular-comp);
    padding: 10px 45px 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: var(--color-ebonyClay);
    z-index: 3;
    &:hover {
        background-color: rgba(98, 108, 119, 0.25);
    }

    &--selected {
        background-color: rgba(98, 108, 119, 0.25);

        &:after {
            content: ' ';
            background-image: url('/images/icons/selected.svg');
            position: absolute;
            width: 14px;
            height: 10px;
            top: 17px;
            right: 17px;
        }
    }

    &:last-child {
        padding-bottom: 16px;
    }

    .dropdown__icon {
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        > img {
            margin: 0 auto;
            filter: invert(1);
        }
    }

    @media (min-width: 1280px) {
        padding: 10px 45px 10px 20px;
    }
}
