/* Семейство MTSCompact */

@font-face {
    font-family: 'MTSCompactBold';
    src: url('/fonts/compact/MTSCompact-Bold/MTSCompact-Bold.eot');
    src: url('/fonts/compact/MTSCompact-Bold/MTSCompact-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/compact/MTSCompact-Bold/MTSCompact-Bold.woff2') format('woff2'),
        url('/fonts/compact/MTSCompact-Bold/MTSCompact-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MTSCompactMedium';
    src: url('/fonts/compact/MTSCompact-Medium/MTSCompact-Medium.eot');
    src: url('/fonts/compact/MTSCompact-Medium/MTSCompact-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/compact/MTSCompact-Medium/MTSCompact-Medium.woff2') format('woff2'),
        url('/fonts/compact/MTSCompact-Medium/MTSCompact-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MTSCompactRegular';
    src: url('/fonts/compact/MTSCompact-Regular/MTSCompact-Regular.eot');
    src: url('/fonts/compact/MTSCompact-Regular/MTSCompact-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/compact/MTSCompact-Regular/MTSCompact-Regular.woff2') format('woff2'),
        url('/fonts/compact/MTSCompact-Regular/MTSCompact-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Семейство MTSWide */
@font-face {
    font-family: 'MTSWideMedium';
    src: url('/fonts/wide/MTSWide-Medium/MTSWide-Medium.eot');
    src: url('/fonts/wide/MTSWide-Medium/MTSWide-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/wide/MTSWide-Medium/MTSWide-Medium.woff2') format('woff2'),
        url('/fonts/wide/MTSWide-Medium/MTSWide-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Семейство MTSText */
@font-face {
    font-family: 'MTSTextRegular';
    src: url('/fonts/text/MTSText-Regular/MTSText-Regular.eot');
    src: url('/fonts/text/MTSText-Regular/MTSText-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/text/MTSText-Regular/MTSText-Regular.woff2') format('woff2'),
        url('/fonts/text/MTSText-Regular/MTSText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
