.counter {
    background-color: var(--color-mts-company);
    color: var(--color-white);
    font-weight: 500;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.counter__variant-16 {
    font-size: 12px;
    line-height: 16px;
    min-width: 16px;
    padding: 0 4px;
}

.counter__variant-20 {
    font-size: 12px;
    line-height: 16px;
    min-width: 20px;
    padding: 2px 6px;
}

.counter__variant-24 {
    font-size: 14px;
    line-height: 20px;
    min-width: 24px;
    padding: 2px 8px;
}

.counter__variant-icon {
    width: 6px;
    height: 6px;
}
