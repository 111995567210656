.button-select {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
}
.button-select__selector {
    cursor: pointer;
    width: 100%;
    height: 44px;
    background-color: rgba(127, 140, 153, 0.35);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-select__selector .button-select__icon {
    > img {
        margin: 0 auto;
        filter: invert(1);
        width: 20px;
        height: 20px;
    }
}
.button-select__selector--selected {
    background-color: var(--color-white);
}
.button-select__selector--selected .button-select__icon {
    > img {
        filter: none;
    }
}
