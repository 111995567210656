.popper-container {
    visibility: hidden;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: var(--color-ebonyClay);
    border-radius: 6px;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12)) drop-shadow(0px 12px 20px rgba(0, 0, 0, 0.14));

    padding-top: 6px;
    padding-bottom: 6px;

    &::-webkit-scrollbar {
        width: 4px;
        padding-right: 4px;
    }

    &::-webkit-scrollbar-button {
        background-color: transparent;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-background-standard);
        border-radius: 100px;
    }
}
