.switch__wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}

.switch__wrapper--reverse {
    flex-direction: row-reverse;
}

.switch__label {
    font: var(--font-p2-regular-comp);
    color: var(--color-chateau);
    cursor: pointer;
}
