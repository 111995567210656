@import 'src/styles';

.header-search {
    position: relative;
    width: 100%;
    width: 352px;
    grid-area: search;
    display: none;
    z-index: 5;

    @media (min-width: 1024px) {
        display: inline-block;
        margin-left: auto;
    }

    @media (min-width: 1920px) {
        margin-right: auto;
        margin-left: 0;
    }
}
.header-search__input-wrapper {
    display: flex;
    flex-direction: row;
    @include mq-tablet-768-up {
        gap: 16px;
    }
}
.header-search__wrapper {
    flex: 1 0;
    width: 100%;
    max-width: 881px;
    overflow: hidden;

    padding: 20px 0;
    border-radius: 12px;
    background: var(--color-black);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);

    @media (min-width: 1024px) {
        position: absolute;
        width: 100vw;
        padding: 10px 10px 19px 0;
        height: auto;
        max-height: 688px;
    }
}

.header-search__drop-list {
    overflow: auto;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 100%;
    max-height: 678.42px;

    &::-webkit-scrollbar-thumb {
        background: rgba(127, 140, 153, 0.35);
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        background: var(--color-black);
        width: 5px;
        height: 5px;
    }
}

.header-search__mobile {
    min-width: 360px;
    padding: 12px 6px 12px 12px;
    position: absolute;
    background: var(--color-black);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    overflow: auto;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar-thumb {
        background: rgba(127, 140, 153, 0.35);
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        background: var(--color-black);
        width: 5px;
        height: 5px;
    }

    @media (min-width: 768px) {
        top: 0;
        padding: 20px 40px;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}

// drop-list для мобильного разрешения
.header-search__mobile .header-search__drop-list {
    position: static;
    background: var(--color-black);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    overflow: auto;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: unset;
    top: 76px;
    left: 2px;
    display: flex;
    flex-direction: column;
    gap: 17px;

    &::-webkit-scrollbar-thumb {
        background: rgba(127, 140, 153, 0.35);
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        background: var(--color-black);
        width: 5px;
        height: 5px;
    }

    @media (min-width: 768px) {
        top: 145px;
    }

    @media (min-width: 768px) {
        top: 66px;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}
