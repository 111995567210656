.favorites-heart {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.favorites-heart--header {
    height: 44px;
    &:hover .favorites-heart__heart-icon {
        transform: scale(0.9);
    }

    & .favorites-heart__heart-icon {
        color: var(--color-chateau);
    }
}

.favorites-heart--action {
    &:hover .favorites-heart__heart-icon {
        transform: scale(1.15);
    }
}

.favorites-heart--disabled {
    .favorites-heart__heart-icon {
        color: var(--color-chateau);
    }

    &:hover .favorites-heart__heart-icon {
        transform: scale(1);
    }
}

.favorites-heart__heart-icon {
    width: 1em;
    height: 1em;
    font-size: 32px;
    color: var(--color-white);
    transition: transform 0.15s ease-out;
}

.favorites-heart__heart-icon--active {
    color: var(--color-discount-back);
}

.favorites-heart__counter {
    position: absolute;
    top: 6px;
    left: 16px;
}
