@mixin mq-phone-only {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mq-tablet-768-up {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin mq-desktop-1024-up {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin mq-desktop-1440-up {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin mq-desktop-1920-up {
    @media (min-width: 1920px) {
        @content;
    }
}
